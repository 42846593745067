import { type IOptionExerciseInfo } from './option-exercise-info'

export const useOptionExerciseDialogStore = defineStore(
  'optionExerciseDialog',
  () => {
    const DEFAULT_DATA: IOptionExerciseInfo = {
      type: 'BUY',
      token: 'uBULLET',
      address: '',
      strikePrice: '0',
      exerciseTimestamp: (Date.now() / 1000).toString(),
    }
    const {
      value: isDialogVisible,
      setTrue: openDialog,
      setFalse: closeDialog,
    } = useBoolean(false)
    const data = ref<IOptionExerciseInfo>(DEFAULT_DATA)

    const setData = (newData: IOptionExerciseInfo) => {
      data.value = newData
    }

    const reset = () => {
      data.value = DEFAULT_DATA
    }

    return {
      data,
      isDialogVisible,
      openDialog,
      closeDialog,
      setData,
      reset,
    }
  },
)
