<template>
  <div class="bullet-table-operations">
    <template v-if="status !== OPTION_STATUS.EXPIRED">
      <ui-tooltip
        v-if="!canExerciseNow"
        content="The exercise period has not commenced yet"
        placement="top"
      >
        <ui-button :disabled="true">Exercise</ui-button>
      </ui-tooltip>
      <ui-button v-else @click="handleClick">Exercise</ui-button>
    </template>
  </div>
</template>
<script setup lang="ts">
import {
  type IOptionExerciseInfo,
  useOptionExerciseDialogStore,
} from '@/components/business/option-exercise-dialog'
// [ Props ]
const props = defineProps({
  data: {
    type: Object as PropType<IOptionExerciseInfo>,
    required: true,
  },
})

const { data } = toRefs(props)

// [ Button Show ]
const { status, canExerciseNow } = useOptionStatus(
  data.value?.exerciseTimestamp,
)

// [ Dialog Control ]
const {
  setData: setOptionExerciseDialogData,
  openDialog: openOptionExerciseDialog,
} = useOptionExerciseDialogStore()
const handleClick = () => {
  setOptionExerciseDialogData(data.value)
  openOptionExerciseDialog()
}

// [ Dialog Control ]
</script>
<style lang="postcss" scoped>
.bullet-table-operations {
  & :deep(.ui-button) {
    width: 150px;
  }
}
</style>
